





























































































































































.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5c6be8;
}
.titleNameNumber {
  height: 40px;
  line-height: 40px;
  padding: 0 1rem;
  border-bottom: 1px solid #eee;
  .l {
    float: left;
  }
  .r {
    float: right;
    span {     
      font-size: 16px;
      font-weight: 600;
    }
  }
}
